import { createSlice } from "@reduxjs/toolkit";

const initialDashboardState = {
  error: null,
  listLoading: false,
  carriersStates: null,
  packagesStates: null,
  actionsLoading: false,
  salesTaxByState: null,
  complianceIssues: null,
  shipmentTransitTime: null,
  mostShippedProducts: null,
  shipmentStatsByState: null,
  shipmentStatsByStatus: null,
  orderPackageaStats: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialDashboardState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    fetchShipmentStatsByStatus: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.shipmentStatsByStatus = data;
    },
    fetchSalesTaxByState: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.salesTaxByState = data;
    },
    fetchShipmentTransitTime: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.shipmentTransitTime = data;
    },
    fetchMostShippedproducts: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.mostShippedProducts = data;
    },
    setCarriersStates: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.carriersStates = data;
    },
    setPackagesStates: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.packagesStates = data;
    },
    setComplianceIssues: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.complianceIssues = data;
    },
    fetchShipmentStatsByState: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.shipmentStatsByState = data;
    },
    fetchOrderPackagesStats: (state, action) => {
      const { data } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.orderPackageaStats = data;
    },
  },
});
