import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  usersList: [],
  totalUsers: 0,
  isLoading: false,
};

export const UsersSlice = createSlice({
  name: "companies",
  initialState: initialState,
  reducers: {
    restListCount: (state, action) => {
      state.totalUsers = action.payload;
    },
    setUsersList: (state, action) => {
      const { items, totalItems } = action.payload.data;
      state.usersList = items;
      state.totalUsers = totalItems;
    },
    setLoading: (state, action) => {
      const value = action.payload;
      state.isLoading = !!value ? value : !state.isLoading;
    },
  },
});
