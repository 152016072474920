import React, { memo } from "react";
import { Field } from "formik";
import { ReactSelect } from "../ReactSelect/ReactSelect";

function FormikSelectField({
  label = "",
  value,
  required = false,
  isMulti = false,
  name = "",
  options = [],
  isClearable = false,
  onChange = null,
  formik,
  error,
  attributeForVal = "value",
  ...props
}) {
  return (
    <Field
      component={() => (
        <ReactSelect
          label={label}
          required={required}
          isMulti={isMulti}
          isClearable={isClearable}
          onChange={(selected) => {
            onChange ? onChange(selected) : formik.setFieldValue([name], selected ? selected[attributeForVal].toString() : "");
          }}
          value={value ? value : formik.values[name]}
          options={options}
          error={error ? error : formik.touched[name] ? formik.errors[name] : ""}
          {...props}
        />
      )}
    />
  );
}
export default memo(FormikSelectField, FormikSelectFieldAreEqual);

function FormikSelectFieldAreEqual(prevProps, nextProps) {
  return (
    prevProps.formik.touched[prevProps.name] === nextProps.formik.touched[nextProps.name] &&
    prevProps.formik.errors[prevProps.name] === nextProps.formik.errors[nextProps.name] &&
    prevProps.formik.values[prevProps.name] === nextProps.formik.values[nextProps.name] &&
    prevProps.options === nextProps.options
  );
}
