import axios from "axios";
import { refreshToken } from "../app/modules/Auth/_redux/authCrud";
import { actions } from "../app/modules/Auth/_redux/authRedux";
import { showResponseMessage } from "../app/modules/ToastMessage/_redux/toastAction";

let isRefreshToken = null;

const setup = (store) => {
  const { dispatch } = store;

  axios.interceptors.response.use(
    (res) => {
      if (res?.data?.message) {
        dispatch(
          showResponseMessage({
            message: res?.data?.message,
            messageType: "success",
            type: "Success",
          })
        );
      }

      return res;
    },
    async (err) => {
      return new Promise((resolve, reject) => {
        const originalConfig = err.config;
        if (originalConfig.url != "/auth/login" && err.response) {
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            try {
              if (!isRefreshToken) {
                const { auth } = store.getState();
                isRefreshToken = refreshToken(auth.refreshToken)
                  .then((rs) => {
                    dispatch(actions.refreshToken(rs?.data));
                    isRefreshToken = null;
                    resolve(axios(originalConfig));
                  })
                  .catch((_err) => {
                    isRefreshToken = null;
                    if (_err.response.status === 403) {
                      dispatch(actions.logout());
                      reject(_err);
                    }
                  });
              } else {
                isRefreshToken.then(() => {
                  resolve(axios(originalConfig));
                });
              }
            } catch (_error) {
              reject(_error);
            }
          } else {
            if (err.response.status === 403) {
              dispatch(actions.logout());
              reject(err);
            } else {
              if (err.message || err?.response?.data?.message) {
                dispatch(
                  showResponseMessage({
                    message: err?.response?.data?.message || err?.message,
                    messageType: "error",
                    type: "Error",
                  })
                );
              }
              reject(err);
            }
          }
        } else {
          if (err.message || err?.response?.data?.message) {
            dispatch(
              showResponseMessage({
                message: err?.response?.data?.message || err?.message,
                messageType: "error",
                type: "Error",
              })
            );
            reject(err);
          }
          reject(err);
        }
      });
    }
  );
};
export default setup;
