import axios from "axios";
export const PRODUCT_TYPE_URL = `${process.env.REACT_APP_VINGO_BASE_URL}/product-type-codes`;

export const createProductType = (ProductType) => {
  return axios.post(PRODUCT_TYPE_URL, ProductType);
};

export async function getAllProductTypeCodes() {
  const { data } = await axios.get(`${process.env.REACT_APP_VINGO_API_URL_V1}/product-type-codes`);
  return data;
}

export const getProductType = (ProductTypeId) => {
  return axios.get(`${PRODUCT_TYPE_URL}/info/${ProductTypeId}`);
};

export const updateProductType = (ProductTypeId, ProductType) => {
  return axios.put(`${PRODUCT_TYPE_URL}/${ProductTypeId}`, ProductType);
};

export const deleteProductType = (ProductTypeId) => {
  return axios.delete(`${PRODUCT_TYPE_URL}/${ProductTypeId}`);
};

export async function getProductTypes() {
  let { data } = await axios.get("/dropdowns/product-types");
  return data;
}

export async function getProductSubTypes(typeId) {
  let { data } = await axios.get(`/dropdowns/product-sub-types/${typeId}`);
  return data;
}

export async function getProductTertiaryTypes(subTypeId) {
  let { data } = await axios.get(`/dropdowns/product-tertiary-types/${subTypeId}`);
  return data;
}