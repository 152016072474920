const errorHandler = (error) => {
  // if (error?.response?.status === 401 && window.location.pathname !== "/auth/login") {
  //   localStorage.clear();
  //   window.location.href = "/auth/login";
  // }
  return Promise.reject(error);
};

const responseHandler = (response) => {
  // if ((response?.data?.status === 401 || response?.data?.statusCode === 401) && window.location.pathname !== "/auth/login") {
  //   localStorage.clear();
  //   window.location.href = "/auth/login";
  //   return;
  // }
  return response;
};

export default function setupAxios(axios, store) {
  axios.defaults.baseURL = process.env.REACT_APP_VINGO_API_URL_V1;
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    },
    (error) => errorHandler(error)
  );

  axios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
  );
}
