import React from "react";
import Select from "react-select";
import Info from "../../../assets/icons/exclamation.svg";

export const ReactSelect = (props) => {
  const { isMulti, onChange, options, value, error, isDisabled, label, required, isClearable = false, showLabel = true } = props;

  const defaultValue = (options, value) => {
    if (Array.isArray(value) && value?.length) {
      return options ? options.filter((item) => value.includes(item.value)) : [];
    }
    return options ? options.find((option) => option.value == value || option.label == value) : "";
  };

  const customStyles = () => ({
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    placeholder: (styles) => ({ ...styles, fontSize: "12px" }),
    control: (baseStyles) => ({
      ...baseStyles,
     fontSize: "12px",
    }),
    option: (baseStyles) => ({
      ...baseStyles,
      fontSize: "12px",
    }),
  });
  
  return (
    <div className="relative ring-input">
      {showLabel && (
        <label className={`md:text-sm text-xs ${label ? "inline-block" : ""}`}>
          {label}
          {required && <span className="text-Sonia md:text-sm text-xs">*</span>}
        </label>
      )}

      <Select
        menuPlacement="auto"
        menuShouldBlockScroll={true}
        isClearable={isClearable}
        isDisabled={isDisabled}
        menuPortalTarget={document.body}
        styles={customStyles(value)}
        placeholder={label}
        isMulti={isMulti}
        value={defaultValue(options, value)}
        onChange={(option) => onChange(option)}
        options={options}
        className="react-select"
    
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          colors: {
            ...theme.colors,
            text: "orangered",
            primary: "#2f3033 !important",
          },
        })}
        {...props}
      />
      {error ? (
        <div className="invalid-feedback text-red-600  pl-4 pt-2 flex sm:text-sm text-xs justify-end absolute -bottom-6 right-0">
          <img src={Info} alt="Info" className="sm:w-4 w-4 cursor-pointer mr-2" width="16px" height="auto" />
          {error}
        </div>
      ) : null}
    </div>
  );
};
