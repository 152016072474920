import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  warehousesList: [],
  totalWarehouses: 0,
  isLoading: false,
};

export const warehousesSlice = createSlice({
  name: "warehouses",
  initialState: initialState,
  reducers: {
    restListCount: (state, action) => {
      state.totalWarehouses = action.payload;
    },
    setWarehousesList: (state, action) => {
      const { items, totalItems } = action.payload.data;
      state.warehousesList = items;
      state.totalWarehouses = totalItems;
    },
    setLoading: (state, action) => {
      const value = action.payload;
      state.isLoading = !!value ? value : !state.isLoading;
    },
  },
});
