import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  feeSchedulesList: [],
  totalFeeSchedules: 0,
  copiedFeeScheduleId: null,
  isLoading: false,
};

export const feeScheduleSlice = createSlice({
  name: "feeSchedules",
  initialState: initialState,
  reducers: {
    restListCount: (state, action) => {
      state.totalFeeSchedules = action.payload;
    },
    setFeeSchedulesList: (state, action) => {
      const { items, totalItems } = action.payload.data;
      state.feeSchedulesList = items;
      state.totalFeeSchedules = totalItems;
    },
    setLoading: (state, action) => {
      const value = action.payload;
      state.isLoading = !!value ? value : !state.isLoading;
    },
    setCopyFeeScheduleId: (state, action) => {
      const id = action.payload;
      state.copiedFeeScheduleId = id;
    },
    clearFeeScheduleId: (state) => {
      state.copiedFeeScheduleId = null;
    },
  },
});
