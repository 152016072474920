import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  RefreshToken: "[Refresh Token] Action",
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  refreshToken: undefined,
};

export const reducer = persistReducer(
  {
    storage,
    key: "v726-demo1-auth",
    whitelist: ["authToken", "parentId", "childrenId", "user", "refreshToken"],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, user, refreshToken } = action.payload;
        return { authToken, user: user, refreshToken: refreshToken };
      }
      case actionTypes.RefreshToken: {
        const { authToken, refreshToken } = action.payload;
        return { ...state, authToken, refreshToken };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  refreshToken: (data) => ({
    type: actionTypes.RefreshToken,
    payload: { authToken: data?.data?.access?.token, refreshToken: data?.data?.refresh?.token },
  }),
  login: (data) => ({
    type: actionTypes.Login,
    payload: { authToken: data?.tokens?.access?.token, user: data?.user, refreshToken: data?.tokens?.refresh?.token },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({
    type: actionTypes.UserLoaded,
    payload: { user },
  }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   yield put(actions.requestUser());
  // });
  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser());
  // });
  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const {
  //     data: { data: user },
  //   } = yield getUserByToken();
  //   if (user.defaultAccounts) {
  //     yield put(actions.setChildrenAccount(user.defaultAccounts.child));
  //     yield put(actions.setParentAccount(user.defaultAccounts.parent));
  //     // yield put(actions.fetchSelectedChildAccount(user.defaultAccounts.child));
  //     // yield put(
  //     // 	actions.fetchSelectedEnterpriseAccount(user.defaultAccounts.parent)
  //     // );
  //   }
  //   yield put(actions.fulfillUser(user));
  // });
  // yield takeLatest(
  //   actionTypes.FetchSelectedChildAccount,
  //   function* FetchSelectedChildAccount(action) {
  //     const { childId } = action.payload;
  //     const {
  //       data: { data },
  //     } = yield getSelectedAccount(childId);
  //     yield put(actions.addSelectedChildAccount(data));
  //   }
  // );
  // yield takeLatest(
  //   actionTypes.FetchSelectedEnterpriseAccount,
  //   function* FetchSelectedEnterpriseAccount(action) {
  //     const { parentId } = action.payload;
  //     const {
  //       data: { data },
  //     } = yield getSelectedAccount(parentId);
  //     yield put(actions.setSelectedEnterprise(data));
  //   }
  // );
}
