import React, { memo } from "react";
import NoData from "../../../assets/icons/No-data-pana.svg";

const EmptyData = ({ header, description, run, handleRun, fullHeight = true }) => {
  return (
    <div
      className={`flex items-center Class
    Properties
    flex-row	flex-direction: row;
    flex-row-reverse	flex-direction: row-reverse;
    flex-col  flex- justify-center ${fullHeight ? "empty-data" : "min-h-[300px]"}`}
    >
      <div className="text-center max-w-xs">
        <img className="m-auto" src={NoData} alt="No Record Found" width="auto" height="auto" />
        <p className="text-darkBlack font-medium mt-2 text-lg">{header}</p>
        <p className="text-lighterGray text-sm">{description}</p>
      </div>
      {run && (
        <button className="btn-primary mt-2 px-6" onClick={handleRun} aria-label="Run">
          Run
        </button>
      )}
    </div>
  );
};
export default memo(EmptyData, MemoEmptyData);
function MemoEmptyData(prevProps, nextProps) {
  return prevProps.header === nextProps.header && prevProps.description === nextProps.description;
}
