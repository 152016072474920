import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NoDataFound from "../../../../components/NoDataFound";
import Spinner from "../../../../components/Spinner";
import useFetch from "../../../../hooks/useFetch";
import { getBillOfLadingReport } from "../../_redux/reportsCrud";
const PrintReport = () => {
  const history = useHistory();
  const { data, refetch } = useFetch(getBillOfLadingReport, { enabled: false });
  const { manifestNumbers } = useSelector((state) => state.reports);
  useEffect(() => {
    if (manifestNumbers.length > 0) {
      refetch({ manifestNumbers });
    } else {
      history.push("/reports/3tier-manifest");
    }
  }, [manifestNumbers]);

  return (
    <>
      {data ? (
        data?.length > 0 ? (
          data?.map((report, index) => (
            <div key={index + "report"} className="print:h-[100vh] mb-[20vh] print:mb-[0vh] px-3 print:px-0">
              <img src={"https://www.vin-global.net/wp-content/uploads/2020/06/vin-globol-logo-for-web.png"} className="w-[220px] h-[100px]" alt="Logo" />
              <div className="bg-vin text-white font-bold flex justify-between items-center px-3 my-4">
                <h2>BILL OF LADING</h2>
                <div className="flex gap-3">
                  <p>BOL #: {report?.manifest_number}</p>
                  <p>DATE: {report?.manifest_date}</p>
                </div>
              </div>
              <div className="flex gap-3 items-center justify-between text-xs">
                <div>
                  <p>From: {report?.from_location?.location}</p>
                  <p>{report?.from_location?.address}</p>
                  <p>{report?.from_location?.city + " " + report?.from_location?.state + ", " + report?.from_location?.zip_code}</p>
                  <p>Phone: {report?.from_location?.phone}</p>
                </div>
                <div>
                  {report?.to?.length > 1 ? (
                    <p>To: Multiple</p>
                  ) : (
                    <p>
                      <p>To:</p>
                      <p>{report?.to?.[0]?.recipient}</p>
                      <p>{report?.to?.[0]?.address}</p>
                      <p>{report?.to?.[0]?.state + " " + report?.to?.[0]?.city + ", " + report?.to?.[0]?.zip_code}</p>
                    </p>
                  )}{" "}
                </div>
                <div>
                  <img src={report?.barcode} alt="BarCode" width={400} />
                </div>
                <div>
                  <p>Consignee: {report?.consignee}</p>
                  <p>Destination: {report?.destination}</p>
                  <p>Instructions: {report?.instructions}</p>
                </div>
              </div>
              <table className="table-auto w-[100%] border-b my-5 text-sm">
                <thead>
                  <tr className="bg-vin text-white">
                    <th>Recipient Name</th>
                    <th>Pkg ID</th>
                    <th>Order #</th>
                    <th>Client</th>
                    <th>Unit Qty</th>
                    <th>Service</th>
                    <th>Tracking #</th>
                  </tr>
                </thead>
                <tbody>
                  {report?.packages?.map((packageItem, index) => (
                    <tr key={index + "package"} className="text-center">
                      <td>{packageItem?.recipient}</td>
                      <td>{packageItem?.pkg_id}</td>
                      <td>{packageItem?.order_number}</td>
                      <td>{packageItem?.client}</td>
                      <td>{packageItem?.quantity}</td>
                      <td>{packageItem?.service}</td>
                      <td>{packageItem?.tracking_number}</td>
                    </tr>
                  ))}

                  <tr className="text-center border-t border-[#000]">
                    <td className="font-bold">Totals</td>
                    <td className="font-bold">{report?.total_package}</td>
                    <td></td>
                    <td></td>
                    <td className="font-bold">{report?.total_quantity}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <div className="break-after-column">
                <h3 className="font-bold">Received By:</h3>
                <p className="my-4 mt-0 text-xs flex gap-2 items-baseline pt-1">
                  Company: <div className="border-b border-[#000] border-[#000] w-[250px] h-[1px]"></div>
                </p>
                <p className="my-4 mt-0 text-xs flex gap-2 items-baseline">
                  Name, ID# <div className="border-b border-[#000] w-[250px] h-[1px] d-block"></div>
                </p>
                <p className="my-4 text-xs flex gap-2 items-baseline">
                  Signature: <div className="border-b border-[#000] w-[250px] h-[1px]"></div> Date: <div className="border-b border-[#000] w-[250px] h-[1px]"></div>
                </p>
              </div>
            </div>
          ))
        ) : (
          <NoDataFound />
        )
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default PrintReport;
