import React, { lazy, Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { UnAutorizedContentRoute } from "../../../components/UnAuthorizedRoute/UnAuthorizedRoute";
import { LayoutSplashScreen } from "../../../Context/LayoutContext";

const Login = lazy(() => import("./Login"));
const VerifyEmail = lazy(() => import("./VerifyEmail"));
const ChangePassword = lazy(() => import("./ChangePassword"));

export function AuthPage() {
  const dummyLogo = "https://www.vin-global.net/wp-content/uploads/2020/06/vin-globol-logo-for-web.png";
  return (
    <>
      <div className="bg-gray min-h-screen sm:pb-20 pb-2">
        <img className="sm:w-49 2 m-auto sm:pt-24 pt-10 pb-10 sm:pb-12 w-32" src={dummyLogo} alt="Logo" width="auto" height="auto" />
        <div className="bg-white sm:max-w-8xl sm:rounded-20 rounded-xl sm:py-16 sm:px-14 m-auto max-w-95% py-6 px-6">
          <div className="">
            <Suspense fallback={<LayoutSplashScreen />}>
              <Switch>
                <UnAutorizedContentRoute path="/auth/login" component={Login} />
                <UnAutorizedContentRoute path="/auth/verify-email" component={VerifyEmail} />
                <UnAutorizedContentRoute path="/auth/change-password" component={ChangePassword} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}
