import React from "react";
import Info from "../../../assets/icons/exclamation.svg";

const inputLabel = ({ label, touched, error, customFeedbackLabel }) => {
  if (touched && error) {
    return (
      <div className="invalid-feedback text-red-600  pl-4 pt-2 flex sm:text-sm text-xs justify-end absolute -bottom-6 truncate right-0">
        <img src={Info} className="sm:w-4 w-4 cursor-pointer mr-2" alt="Info" width="auto" height="auto" />
        {error}
      </div>
    );
  }

  return (
    <div className="feedback">
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {!customFeedbackLabel && (
        <>
          Please enter <b>{label}</b>
        </>
      )}
    </div>
  );
};

const selectLabel = ({ label, touched, error, customFeedbackLabel }) => {
  if (touched && error) {
    return (
      <div className="invalid-feedback text-red-600  pl-4 pt-2 flex sm:text-sm text-xs justify-end absolute -bottom-6 truncate right-0">
        <img src={Info} className="sm:w-4 w-4 cursor-pointer mr-2" width="auto" height="auto" alt="Info" /> {error}
      </div>
    );
  }

  return (
    <div className="feedback">
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {!customFeedbackLabel && label && (
        <>
          Please select <b>{label}</b>
        </>
      )}
    </div>
  );
};

export function FieldFeedbackLabel({ label, touched, error, type, customFeedbackLabel }) {
  switch (type) {
    case "text":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    case "email":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    case "password":
      return inputLabel({ label, touched, error, customFeedbackLabel });
    default:
      return selectLabel({ label, touched, error, customFeedbackLabel });
  }
}
