import { useContext } from "react";
import AuthContext from "../Context/Auth";

export default function useAuthHook() {
  const { token } = useContext(AuthContext);

  const hasPermission = (value, { checkAllScopes } = { checkAllScopes: false }) => {
    if (typeof value == "string") return token?.guardName?.includes(value);
    if (Array.isArray(value)) {
      if (checkAllScopes) {
        return value.every((element) => {
          return token?.guardName?.includes(element);
        });
      } else {
        return value.some((r) => token?.guardName?.includes(r));
      }
    }
    return false;
  };
  const getResourcePermission = (index) => {
    return token?.resourceScope?.[index];
  };
  const hasResourcePermission = (value) => {
    if (typeof value == "string") return token?.resourceScope?.includes(value);
    if (Array.isArray(value)) {
      return value.some((element) => {
        return token?.resourceScope?.includes(element);
      });
    }
  };
  return {
    hasResourcePermission,
    hasPermission,
    getResourcePermission,
  };
}
