import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { LayoutSplashScreen } from "./Context/LayoutContext";

import useAuthHook from "./hooks/useAuthHook";
import Layout from "./hoc/Layout";

const Dashboard = lazy(() => import("./modules/Dashboard"));
const CompaniesRoute = lazy(() => import("./modules/Companies/pages/CompaniesRouter"));
const UsersRoute = lazy(() => import("./modules/Users/pages/UsersRouter"));
const FeeScheduleRoute = lazy(() => import("./modules/FeeSchedules/pages/FeeScheduleRouter"));
const KitRoute = lazy(() => import("./modules/Kits/pages/KitsRouter"));
const ProductRoute = lazy(() => import("./modules/Products/pages/ProductRouter"));
const ContainerRoute = lazy(() => import("./modules/Containers/pages/ContainerRouter"));
const OrderRoute = lazy(() => import("./modules/Orders/pages/OrderRouter"));
const ReportRoute = lazy(() => import("./modules/Reports/pages/ReportsRouter"));
const WarehouseRoute = lazy(() => import("./modules/Warehouse/pages/WarehouseRouter"));
const HelpDeskRoute = lazy(() => import("./modules/HelpDesk/pages/HelpDeskRouter"));

export default function BasePage() {
  const { hasResourcePermission, getResourcePermission } = useAuthHook();
  let { url } = useRouteMatch();
  return (
    <Layout>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {/* {hasResourcePermission("Disabled") && <Route path="/dashboard" component={Dashboard} />} */}
          {hasResourcePermission("Companies") && <Route path="/companies" component={CompaniesRoute} />}
          {hasResourcePermission(["Users", "Roles", "Permissions"]) && <Route path="/users" component={UsersRoute} />}
          {hasResourcePermission("Fee-Schedules") && <Route path="/fee-schedules" component={FeeScheduleRoute} />}
          {hasResourcePermission("Kits") && <Route path="/kits" component={KitRoute} />}
          {hasResourcePermission("Products") && <Route path="/products" component={ProductRoute} />}
          {hasResourcePermission("Containers") && <Route path="/containers" component={ContainerRoute} />}
          {hasResourcePermission("Orders") && <Route path="/orders" component={OrderRoute} />}
          {hasResourcePermission(["3tier-recap", "3tier-manifest", "invoices-and-purchase-orders"]) && <Route path="/reports" component={ReportRoute} />}
          {hasResourcePermission("Warehouses") && <Route path="/warehouses" component={WarehouseRoute} />}
          {/* <Route path="/help-desk" component={HelpDeskRoute} /> */}

          {/* Redirect from any route to /dashboard (in case user type manual URL in browser)  */}
          <Route render={() => <Redirect to={`${url}${getResourcePermission(1)?.toLowerCase?.()}`}></Redirect>} />
        </Switch>
      </Suspense>
      </Layout>
  );
}
