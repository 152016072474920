import React from "react";
import { getIn } from "formik";
import { FieldFeedbackLabel } from "../FieldFeedbackLabel";

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  nested = false,
  className = "",
  Required = true,
  hideError = false,
  suggestion = null,
  error = null,
  touched: touchedProp = null,
  ...props
}) {
  return (
    <>
      <div className="text-left relative">
        <label className=" text-darkBlack">
          <span className="md:text-sm text-xs">{label}</span>
          {Required && <span className="text-Sonia md:text-sm text-xs">*</span>}
          {suggestion && <span className="md:text-sm text-xs ml-2 truncate text-lightGray">{suggestion}</span>}
          <input
            className={`default-input mt-1 ${className} disabled:opacity-50 disabled:cursor-not-allowed`}
            min={type === "number" && !props.min ? 0 : props.min}
            {...field}
            {...props}
            type={type}
            onKeyDown={(evt) => {
              if (type === "number") {
                (evt.key === "e" || evt.key === "-") && evt.preventDefault();
              }
            }}
          />
        </label>
        {withFeedbackLabel && !hideError && (
          <FieldFeedbackLabel
            error={error ? error : nested ? getIn(errors, field.name) : errors[field.name]}
            touched={touchedProp === null ? (nested ? getIn(touched, field.name) : touched[field.name]) : touchedProp}
            label={label}
            type={type}
            customFeedbackLabel={customFeedbackLabel}
          />
        )}
      </div>
    </>
  );
}
