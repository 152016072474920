import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { AuthInit } from "./modules/Auth";
import { Routes } from "../app/Routes";
import { LayoutSplashScreen } from "./Context/LayoutContext";
import { ToastProvider } from "react-toast-notifications";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/Error/ErrorFallback";
import AuthProvider from "../app/hoc/Auth";
import ToastMessage from "./modules/ToastMessage";

export default function App({ store, persistor, basename }) {
  return (
    /** Error Boundary */
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => window.location.reload()}>
      {/* Provide Redux store */}
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/* Render routes with provided `Layout`. */}
              <ToastProvider>
                <AuthInit>
                  <AuthProvider>
                    <Routes />
                  </AuthProvider>
                </AuthInit>
                <ToastMessage />
              </ToastProvider>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
}
