import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import { shipmentsSlice } from "../app/modules/Shipments/_redux/shipmentSlice";

import { companiesSlice } from "../app/modules/Companies/_redux/companiesSlice";
import { UsersSlice } from "../app/modules/Users/_redux/UserSlice";
import { KitsSlice } from "../app/modules/Kits/_redux/KitSlice";
import { ProductSlice } from "../app/modules/Products/_redux/ProductSlice";
import { OrderSlice } from "../app/modules/Orders/_redux/OrderSlice";

import { ContainerSlice } from "../app/modules/Containers/_redux/Slice";

import { feeScheduleSlice } from "../app/modules/FeeSchedules/_redux/FeeScheduleSlice";
import { toastSlice } from "../app/modules/ToastMessage/_redux/toastSlice";
import { dashboardSlice } from "../app/modules/Dashboard/_redux/dashboardSlice";
import { reportsSlice } from "../app/modules/Reports/_redux/reportsSlice";
import { warehousesSlice } from "../app/modules/Warehouse/_redux/WarehosueSlice";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  whitelist: [""],
};

const shipmentsPersistConfig = {
  key: "shipments",
  storage: storage,
  whitelist: ["shipmentNumber"],
};

const reducers = combineReducers({
  auth: auth.reducer,
  shipments: persistReducer(shipmentsPersistConfig, shipmentsSlice.reducer),
  companies: companiesSlice.reducer,
  containers: ContainerSlice.reducer,
  users: UsersSlice.reducer,
  feeSchedules: feeScheduleSlice.reducer,
  kits: KitsSlice.reducer,
  products: ProductSlice.reducer,
  orders: OrderSlice.reducer,
  toastMessages: toastSlice.reducer,
  dashboard: dashboardSlice.reducer,
  reports: reportsSlice.reducer,
  warehouses: warehousesSlice.reducer,
});

export const rootReducer = persistReducer(rootPersistConfig, reducers);

export function* rootSaga() {
  yield all([auth.saga()]);
}
