import React from "react";
import AuthContext from "../Context/Auth";
import jwt_decode from "jwt-decode";
import { useSelector, shallowEqual } from "react-redux";

export default function AuthProvider({ children }) {
  const [token, setToken] = React.useState();

  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth && auth.authToken,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    decodeToken();
  }, [authToken]);

  const decodeToken = () => {
    let token = authToken;
    if (token) {
      let decodeToken = jwt_decode(authToken);
      setToken(decodeToken);
    }
  };

  return (
    <>
      <AuthContext.Provider value={{ token, setToken }}>{children}</AuthContext.Provider>
    </>
  );
}
