import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import PrintReport from "./modules/Reports/pages/3tierManifest/PrintReport";
import HelpDeskRoute from "./modules/HelpDesk/pages/HelpDeskRouter";
import ProductTypeSetting from "./modules/Config/pages/ConfigRouter";

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth && auth.user != null,
    }),
    shallowEqual
  );

  ///Routing
  return (
    <Switch>
      {!isAuthorized ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/logout" component={Logout} />
      <Route path={"/manifest-report/print"}>
        <PrintReport />
      </Route>

      <Route path="/help-desk" component={HelpDeskRoute} />
      <Route path="/settings" component={ProductTypeSetting} />

      {!isAuthorized ? <Redirect to="/auth/login" /> : <BasePage />}
    </Switch>
  );
}
