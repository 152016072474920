import { createSlice } from "@reduxjs/toolkit";

const initialCompaniesState = {
  companiesList: [],
  totalCompanies: 0,
  isLoading: false,
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState: initialCompaniesState,
  reducers: {
    restListCount: (state, action) => {
      state.totalCompanies = action.payload;
    },
    setCompaniesList: (state, action) => {
      const { items, totalItems } = action.payload.data;
      state.companiesList = items;
      state.totalCompanies = totalItems;
    },
    setLoading: (state, action) => {
      const value = action.payload;
      state.isLoading = !!value ? value : !state.isLoading;
    },
  },
});
