import React from "react";
import { useRouteMatch, Link } from "react-router-dom";

export default function ProductTypeSetting() {
  const { path } = useRouteMatch();

  const sections = [
    {
      name: "Product Types Setting",
      img: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4,13h6a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1H4A1,1,0,0,0,3,4v8a1,1,0,0,0,1,1ZM3,20a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V16a1,1,0,0,0-1-1H4a1,1,0,0,0-1,1Zm10,0a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V13a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1Zm1-10h6a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1V9a1,1,0,0,0,1,1Z"
          />
        </svg>
      ),
    },
    
  ];
  const sectionRoute = ["product-type-setting"];

  return (
    <div>
      <div className="pb-4 w-3/5  mt-2.5 mx-auto">
        <div>
          {sections.map((section, index) => (
            <Link key={index} className="h-16 mb-2.5 card grid grid-cols-12 gap-4" to={`${path}/${sectionRoute[index]}`}>
              <div>{section.img}</div>
              <div className="col-span-10 font-medium leading-tight text-xl mt-0 mb-2 text-600 text-vin">{section.name}</div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
