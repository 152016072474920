import { Field, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import FormikSelectField from "../../../../components/Input/FormikSelectField";
import Spinner from "../../../../components/Spinner";
import { createProductType, deleteProductType, getProductTypes, getProductSubTypes, getProductTertiaryTypes, getAllProductTypeCodes } from "../../_redux/productTypeServices";
import { Input } from "../../../../components/Input";
import { showResponseMessage } from "../../../ToastMessage/_redux/toastAction";

export default function ProductTypeSetting() {
  const [selectedProductType] = useState({});
  const [productTypes, setProductTypes] = useState([]);
  const [productSubTypes, setProductSubTypes] = useState([]);
  const [productTertiaryTypes, setProductTertiaryTypes] = useState([]);
  const [productTypeCodeList, setProductTypeCodeList] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      productTypeId: selectedProductType && selectedProductType?.productType || "",
      productSubTypeId: selectedProductType && selectedProductType?.productSubType || "",
      productTertiaryTypeId: selectedProductType && selectedProductType?.productTertiaryType || "",
      typeCode: selectedProductType && selectedProductType?.productSubType || "",
    },
    onSubmit: async (values) => {
      try {
        await createProductType(values);
        showResponseMessage({
          message: "Product Type Class",
          messageText: "Product Type Class Created",
          messageType: "success",
          type: "Success",
        })
        formik.resetForm({values: {
          productTypeId: "",
          productSubTypeId: "",
          productTertiaryTypeId: "",
          typeCode: "",
        }})
        getProductTypeCodeList()
      } catch (error) {
        showResponseMessage({
          message: "Product Type Class",
          messageText: error?.response?.data?.message || "Product Type Class Code should be unique",
          messageType: "error",
          type: "Error",
        })
      }
    },
  });

  const handleDeleteProductType = (id) => {
    deleteProductType(id).then(() => {
      showResponseMessage({
        message: "Product Type Class",
        messageText: "Product Type Class Deleted",
        messageType: "success",
        type: "Success",
      })
      getProductTypeCodeList()
    })
  }

  const getProductsTypesData = () =>
    getProductTypes().then((res) => {
      setProductTypes(res?.data);
    });

  const getProductsSubTypesData = (typeId) => {
    getProductSubTypes(typeId).then((res) => {
      setProductSubTypes(res?.data);
      formik.setFieldValue("hasSubTypes", !!(res?.data.length > 0));
    });
  };

  const getProductsTertiaryTypesData = (subTypeId) => {
    getProductTertiaryTypes(subTypeId).then((res) => {
      setProductTertiaryTypes(res?.data);
      formik.setFieldValue("hasTertiaryTypes", !!(res?.data.length > 0));
    });
  };

  const getProductTypeCodeList = () => {
    getAllProductTypeCodes().then((res) => {
      setProductTypeCodeList(res?.data);
    });
  };

  useEffect(() => {
    Promise.all([
      getProductsTypesData(),
      getProductTypeCodeList(),
    ]).finally(() => {
      setIsDataLoading(false);
    });
  }, []);

  return (
    <div className="pb-4  mt-8 mx-8">
      {isDataLoading && <Spinner />}

      <p className="text-4xl my-8">Product Type Setting</p>
      <form onSubmit={formik.handleSubmit}>
        <FormikProvider value={formik}>
          <div className="md:grid gap-4 grid-cols-4 mx-auto mt-8">
            <FormikSelectField
              label="Product Type"
              required={true}
              name="productTypeId"
              value={productTypes.find(({ id }) => id === formik.values.productTypeId)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              options={productTypes}
              onChange={(selected) => {
                formik.setFieldValue("productTypeId", selected ? selected.id.toString() : "");
                selected?.id && getProductsSubTypesData(selected?.id);
                formik.setFieldValue("productSubTypeId", "");
              }}
              formik={formik}
            />
            {formik.values.hasSubTypes && (
              <FormikSelectField
                label="Product Sub Type"
                required={false}
                name="productSubTypeId"
                value={productSubTypes.find(({ id }) => id === formik.values.productSubTypeId)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                options={productSubTypes}
                onChange={(selected) => {
                  formik.setFieldValue("productSubTypeId", selected ? selected.id.toString() : "");
                  selected?.id && getProductsTertiaryTypesData(selected?.id);
                  formik.setFieldValue("productTertiaryId", "");
                }}
                formik={formik}
              />
            )}
            {formik.values.hasTertiaryTypes && (
              <FormikSelectField
                label="Product Tertiary Type"
                required={false}
                name="productTertiaryTypeId"
                value={productTertiaryTypes.find(({ id }) => id === formik.values.productTertiaryTypeId)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                options={productTertiaryTypes}
                onChange={(selected) => {
                  formik.setFieldValue("productTertiaryTypeId", selected ? selected.id.toString() : "");
                }}
                formik={formik}
              />
            )}
            <Field name="typeCode" component={Input} placeholder="Product Type Code" label="Type Code" autoComplete="off" customFeedbackLabel=" " />

          </div>
          <div className="flex justify-end my-2">
            <button disabled={!formik.values.productTypeId || !formik.values.typeCode} className="flex bg-primary text-white px-4 py-2 rounded-md disabled:opacity-10" type="submit">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
              </svg>
              Add
            </button>
          </div>
        </FormikProvider>
      </form>
      <table className="w-full border-collapse border border-slate-400">
        <thead className="text-primary text-center text-md bg-vin font-medium border-collapse border border-vin">
          <tr>
            <td>Type Code</td>
            <td>Product Type</td>
            <td>Product Sub Type</td>
            <td>Product Tertiary Type</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody className="text-center text-primary text-md font-normal border-collapse border border-slate-400">
          {productTypeCodeList?.map((productType) => (
            <tr key={productType.id}>
              <td>{productType?.type_code}</td>
              <td>{productType?.product_type?.name}</td>
              <td>{productType?.product_sub_type?.name}</td>
              <td>{productType?.product_tertiary_type?.name}</td>
              <td><button onClick={() => handleDeleteProductType(productType?.id)}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
              </svg>
              </button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
