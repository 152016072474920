import { useState, useEffect } from "react";
const useFetch = (fetchData, { enabled = true, isAlwaysEnabled = true } = { enabled: true, isAlwaysEnabled: true }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (enabled && isAlwaysEnabled) {
      setIsLoading(true);
      fetchData()
        .then((result) => setData(result?.data))
        .finally(() => setIsLoading(false));
    }
    return () => setData(null);
  }, []);

  const refetch = (params) => {
    if (isAlwaysEnabled) {
      setIsLoading(true);
      return fetchData(params)
        .then((result) => {
          setData(result?.data);
          return result;
        })
        .finally(() => setIsLoading(false));
    }
  };
  return { data, setData, refetch, isLoading };
};
export default useFetch;
