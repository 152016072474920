import React, { useEffect } from "react";
import LogoBlue from "../../../assets/icons/logoblue.svg";
import { useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import CaredDown from "../../../assets/icons/carret-down.svg";
import Notification from "../../../assets/icons/bel.svg";
import HeaderNotification from "../Notification/Header/HeaderNotification";
const dummyLogo = "https://www.vin-global.net/wp-content/uploads/2020/06/vin-globol-logo-for-web.png";

export const Header = (props) => {
  const { toggleSideMenu, isNotificationsMenuOpen, toggleNotificationsMenu, isMenuListOpen, toggleMenuList, /*setIsOpen*/ menuListRef } = props;
  const { user } = useSelector((state) => state.auth, shallowEqual);
  useEffect(() => {}, [user?.updatedAt]);

  return (
    <header
      className={"z-10 py-4 bg-white dark:bg-gray-800 shadow-xs print:hidden" + (props.noNavBar ? "container flex items-center justify-between w-full md:px-6 px-3 mx-auto" : "")}
    >
      {props.noNavBar ? (
        <div className="container flex items-center justify-between h-full md:px-6 px-3 mx-auto">
          <div>
            <Link to="/dashboard">
              <img src={dummyLogo} className=" px-4 max-w-[20%]" alt="Logo" width="auto" height="auto" />
            </Link>
          </div>
        </div>
      ) : null}
      <div className="flex items-center justify-between h-full md:px-6 px-3 mx-auto">
        {props.children}
        </div>
      <div className="container flex items-center justify-between h-full md:px-6 px-3 mx-auto  ">
        <button className="p-1 mr-5 -ml-1 rounded-md md:hidden focus:outline-none focus:shadow-outline-purple" onClick={toggleSideMenu} aria-label="Menu">
          <svg className="w-6 h-6" id="Group_550" data-name="Group 550" xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
            <path id="Path_757" data-name="Path 757" d="M16,1a1,1,0,0,0-1-1H1A1,1,0,1,0,1,2H15a1,1,0,0,0,1-1Z" transform="translate(0 0)" fill="#1e1014" />
            <path id="Path_758" data-name="Path 758" d="M16,13a1,1,0,0,0-1-1H1a1,1,0,0,0,0,2H15a1,1,0,0,0,1-1Z" transform="translate(0 0)" fill="#1e1014" />
            <path id="Path_759" data-name="Path 759" d="M15,6a1,1,0,1,1,0,2H7A1,1,0,0,1,7,6Z" transform="translate(-6 0)" fill="#1e1014" />
          </svg>
        </button>
       
        <div className="flex flex-1 lg:mr-32">
          <img src={LogoBlue} className="w-32 md:hidden" alt="Vin-Global" width="128px" height="auto" />
        </div>

        <ul className="flex items-center flex-shrink-0 md:space-x-5 justify-end">
          <li className="relative">
            <button
              className="relative align-middle rounded-md focus:outline-none focus:shadow-outline-purple"
              aria-label="Notifications"
              aria-haspopup="true"
              onClick={toggleNotificationsMenu}
              title="Notifications"
            >
              <img src={Notification} alt="Notifications" width="auto" height="auto" />
              <span
                aria-hidden="true"
                className="absolute top-0 right-0 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-full dark:border-gray-800"
              ></span>
            </button>
            {isNotificationsMenuOpen && <HeaderNotification onClick={toggleNotificationsMenu} />}
          </li>
          {user?.imageUrl ? (
            <li className="relative ml-2">
              <button className="align-middle rounded-full focus:shadow-outline-purple focus:outline-none" aria-label="Account" aria-haspopup="true">
                <img
                  id="imgButton"
                  onClick={toggleMenuList}
                  className="object-cover w-8 h-8 rounded-full border-2 
                border-primary"
                  src={user?.imageUrl}
                  alt="Menu"
                  aria-hidden="true"
                  width="auto"
                  height="auto"
                />
              </button>
            </li>
          ) : (
            ""
          )}
          <li ref={menuListRef} className="relative flex cursor-pointer">
            <div className="md:flex hidden">
              <p className="text-darkBlack text-sm mr-2" id="button">
                {user?.firstName} {user?.lastName}
              </p>
              <img className="w-[10px]" src={CaredDown} alt="CaretDown" width="10px" height="auto" />
            </div>

            {isMenuListOpen && (
              <template className="block">
                <ul
                  className="absolute md:-right-2 -right-1  w-53  py-4 md:top-10 top-6 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-3xl dark:text-gray-300 dark:border-gray-700 dark:bg-gray-700 z-20"
                  // ref={headerRef}
                >
                  <li>
                    <Link
                      className="inline-flex items-center justify-between w-full
											 text-sm text-primary transition-colors
											duration-150 px-4 mt-2"
                      to="/logout"
                    >
                      <span>Log Out</span>
                    </Link>
                  </li>
                </ul>
              </template>
            )}
          </li>
        </ul>
      </div>
    </header>
  );
};
