import { createSlice } from "@reduxjs/toolkit";

const initialNotificationState = {
  listLoading: false,
  actionsLoading: false,
  allNotificationLoading: false,
  allNotifcationActionsLoading: false,
  totalCount: 0,
  notifications: [],
  allNotifications: [],
  error: "",
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: initialNotificationState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
    clearAllNotifications: (state) => {
      state.totalCount = 0;
      state.allNotifications = [];
    },
    startCallAllNoti: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.allNotificationLoading = true;
      } else {
        state.allNotifcationActionsLoading = true;
      }
    },
    catchErrorAllNoti: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.allNotificationLoading = false;
      } else {
        state.allNotifcationActionsLoading = false;
      }
    },
    setNotifications: (state, action) => {
      state.notifications = [...(action.payload.data || []), ...(state.notifications || [])] || [];
      state.listLoading = false;
    },
    updateNotification: (state, action) => {
      const { docId, notification } = action.payload.data;
      if (notification && Object.keys(notification).length > 0) {
        state.notifications = state.notifications.slice().map((item) => {
          if (item.docId === docId) {
            return {
              docId: docId,
              ...notification,
            };
          }
          return item;
        });
      } else {
        const { docId } = action.payload.data;
        state.notifications = state.notifications.filter((item) => {
          return item.docId !== docId;
        });
      }

      state.listLoading = false;
    },
    deleteNotification: (state, action) => {
      const { docId } = action.payload.data;
      state.notifications = state.notifications.filter((item) => {
        return item.docId !== docId;
      });
      state.listLoading = false;
    },
    setAllNotifications: (state, action) => {
      state.allNotifications = [...(action.payload.data || []), ...(state.allNotifications || [])] || [];
      state.allNotificationLoading = false;
    },
    updateAllNotifications: (state, action) => {
      const { docId, notification } = action.payload.data;
      state.allNotifications = state.allNotifications.slice().map((item) => {
        if (item.docId === docId) {
          return {
            docId: docId,
            ...notification,
          };
        }
        return item;
      });
      state.allNotificationLoading = false;
    },
    deleteAllNotification: (state, action) => {
      const { docId } = action.payload.data;
      state.allNotifications = state.allNotifications.filter((item) => {
        return item.docId !== docId;
      });
      state.allNotificationLoading = false;
    },
    setNextNotifications: (state, action) => {
      state.allNotifications = [...state.allNotifications, ...action.payload.data] || [];
      state.allNotificationLoading = false;
    },
    setTotalCount: (state, action) => {
      const { totalCount } = action?.payload?.data;
      state.totalCount = totalCount;
    },
  },
});
