import React, {Suspense} from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../Context/LayoutContext";
import { Header } from "../../../components/Header/Header";
import { HomeIcon } from "@heroicons/react/solid";

import Index from "../index";
import ProductTypeSetting from "./sections/ProductTypeSetting";

const ConfigRouter = () => {
  const { path } = useRouteMatch();

  return (
    <div>
      <Header
        noNavBar={true}
      >
        <Link to="/"><HomeIcon className="text-vin" />Home</Link>
        
      </Header>
      <div className="container max-w-full mx-auto grid">
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Route exact path={`${path}/product-type-setting`}>
              <ProductTypeSetting />
            </Route>
            
            <Route path={`${path}`}>
              <Index />
            </Route>
          </Switch>
        </Suspense>
      </div>
      </div>
  );
};

export default ConfigRouter;
