import React, { useRef, useEffect, useState, Suspense, lazy } from "react";
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../Context/LayoutContext";
import { Header } from "../../../components/Header/Header";
import { HomeIcon } from "@heroicons/react/solid";

import HelpDesk from "../index";
const DashboardHelpDesk = lazy(() => import("./sections/DashboardHelpDesk"));
const CompanyHelpDesk = lazy(() => import("./sections/CompanyHelpDesk"));
const UserHelpDesk = lazy(() => import("./sections/UserHelpDesk"));
const WarehouseHelpDesk = lazy(() => import("./sections/WarehouseHelpDesk"));
const FeeScheduleHelpDesk = lazy(() => import("./sections/FeeScheduleHelpDesk"));
const ProductHelpDesk = lazy(() => import("./sections/ProductHelpDesk"));
const KitHelpDesk = lazy(() => import("./sections/KitHelpDesk"));
const ContainerHelpDesk = lazy(() => import("./sections/ContainerHelpDesk"));
const OrderHelpDesk = lazy(() => import("./sections/OrderHelpDesk"));
const ReportHelpDesk = lazy(() => import("./sections/ReportHelpDesk"));
const DownloadFormat = lazy(() => import("./sections/DownloadFormat"))

const HelpDeskRouter = () => {
  const [isNotificationsMenuOpen, NotificationsMenuOpen] = useState(false);
  const [isMenuListOpen, MenuListOpen] = useState(false);
  const ref = useRef();
  const menuListRef = useRef(null);
  const { path } = useRouteMatch();

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuListRef.current && event.target.id !== "imgButton" && !menuListRef.current.contains(event.target)) {
        MenuListOpen(false);
      } else if (menuListRef.current && menuListRef.current.contains(event.target)) {
        if (!isMenuListOpen || event.target?.id === "button") {
          toggleMenuList();
        } else {
          MenuListOpen(true);
        }
      }
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        SidebarOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuListRef, isMenuListOpen]);

  const toggleNotificationsMenu = () => {
    NotificationsMenuOpen(!isNotificationsMenuOpen);
    MenuListOpen(false);
  };
  const toggleMenuList = () => {
    MenuListOpen(!isMenuListOpen);
    NotificationsMenuOpen(false);
  };

  return (
    <div>
      <Header
        headerRef={ref}
        menuListRef={menuListRef}
        isMenuListOpen={isMenuListOpen}
        toggleMenuList={toggleMenuList}
        toggleNotificationsMenu={toggleNotificationsMenu}
        isNotificationsMenuOpen={isNotificationsMenuOpen}
        noNavBar={true}
      >
        <Link to="/"><HomeIcon className="text-vin" />Home</Link>
        
      </Header>
      <div className="container max-w-full mx-auto grid">
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Route exact path={`${path}/dashboard`}>
              <DashboardHelpDesk />
            </Route>
            <Route exact path={`${path}/companies`}>
              <CompanyHelpDesk />
            </Route>
            <Route exact path={`${path}/users`}>
              <UserHelpDesk />
            </Route>
            <Route exact path={`${path}/warehouses`}>
              <WarehouseHelpDesk />
            </Route>
            <Route exact path={`${path}/fee-schedules`}>
              <FeeScheduleHelpDesk />
            </Route>
            <Route exact path={`${path}/products`}>
              <ProductHelpDesk />
            </Route>
            <Route exact path={`${path}/kits`}>
              <KitHelpDesk />
            </Route>
            <Route exact path={`${path}/containers`}>
              <ContainerHelpDesk />
            </Route>
            <Route exact path={`${path}/orders`}>
              <OrderHelpDesk />
            </Route>
            <Route exact path={`${path}/reports`}>
              <ReportHelpDesk />
            </Route>
            <Route exact path={`${path}/download`}>
              <DownloadFormat />
            </Route>
            <Route path={`${path}`}>
              <HelpDesk />
            </Route>
          </Switch>
        </Suspense>
      </div>
      </div>
  );
};

export default HelpDeskRouter;
