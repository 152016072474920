import axios from "axios";

export const getWholeSellerReport = async (queryData) => {
  const { data } = await axios.post("/reports/3tier-recaps", queryData);
  return data;
};

export const getStatesOfAmerica = async () => {
  const { data } = await axios.get("/general/states/233");
  return data;
};

export const getShipmentDates = async () => {
  const { data } = await axios.get("/dropdowns/shipment-dates");
  return data;
};

export const getInvoicePurchaseOrderReport = async (queryData) => {
  const { data } = await axios.post("/reports/invoices-purchase-order", queryData);
  return data;
};

export const getAllSellers = async () => {
  const { data } = await axios.get("/companies/all");
  return data;
};

export const getAllLocations = async () => {
  const { data } = await axios.get("/warehouses/searchByLocation");
  return data;
};

export const getManifestReport = async (queryData) => {
  const { data } = await axios.post("/reports/manifest-shipping", queryData);
  return data;
};

export const getBillOfLadingReport = async (queryData) => {
  const { data } = await axios.post("/orders/bill-of-lading", queryData);
  return data;
};
