import React, { useEffect, useRef, useState, lazy, Suspense, createContext } from "react";
import { Header } from "../components/Header/Header";
import { getNotifications } from "../modules/Notification/_redux/notificationActions";
import { connect, useSelector, shallowEqual, useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../Context/LayoutContext";

import * as auth from "../modules/Auth/_redux/authRedux";

export const ScrollToTop = createContext({ scrollToTop: () => {} });

const Drawer = lazy(() => import("../components/Drawer/Drawer"));
const Topbar = lazy(() => import("../components/Topbar"));

const Layout = (props) => {
  const dispatch = useDispatch();
  const [isNotificationsMenuOpen, NotificationsMenuOpen] = useState(false);
  const [isMenuListOpen, MenuListOpen] = useState(false);
  const [isSidebarOpen, SidebarOpen] = useState(false);

  const { parentId, childrenId, user } = useSelector((state) => state.auth, shallowEqual);
  const { ProfilePreferences } = user;

  const toggleNotificationsMenu = () => {
    NotificationsMenuOpen(!isNotificationsMenuOpen);
    MenuListOpen(false);
  };
  const toggleMenuList = () => {
    MenuListOpen(!isMenuListOpen);
    NotificationsMenuOpen(false);
  };
  const toggleSideMenu = () => {
    SidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (childrenId) {
      props.fetchSelectedChildAccount(childrenId);
    }
    if (parentId) {
      props.fetchSelectedEnterpriseAccount(parentId);
    }
    if (childrenId && parentId) {
      const data = { parentAccount: parentId, childAccount: childrenId };
      if (ProfilePreferences && ProfilePreferences?.enabledNotifications) {
        dispatch(getNotifications(data));
      }
    }
  }, [parentId, childrenId, ProfilePreferences]);

  const ref = useRef();
  const appRef = useRef(null);
  const menuListRef = useRef(null);
  const drawerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuListRef.current && event.target.id !== "imgButton" && !menuListRef.current.contains(event.target)) {
        MenuListOpen(false);
      } else if (menuListRef.current && menuListRef.current.contains(event.target)) {
        if (!isMenuListOpen || event.target?.id === "button") {
          toggleMenuList();
        } else {
          MenuListOpen(true);
        }
      }
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        SidebarOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuListRef, isMenuListOpen]);

  const scrollToTop = () => {
    appRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <ScrollToTop.Provider value={{ scrollToTop }}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <div
          className="flex bg-foggySummer"
          onClick={() => {
            if (isNotificationsMenuOpen) NotificationsMenuOpen(false);
          }}
        >
          {isSidebarOpen && <Drawer DrawerRef={drawerRef} toggleSideMenu={toggleSideMenu} /> }
          <div className="flex flex-col flex-1 w-full overflow-hidden ">
          {isSidebarOpen &&  <Header
              headerRef={ref}
              menuListRef={menuListRef}
              // setIsOpen={setIsOpen}
              isMenuListOpen={isMenuListOpen}
              toggleMenuList={toggleMenuList}
              toggleSideMenu={toggleSideMenu}
              toggleNotificationsMenu={toggleNotificationsMenu}
              isNotificationsMenuOpen={isNotificationsMenuOpen}
              noNavBar={false}
            />}
            {
              !isSidebarOpen &&
              <Topbar />
            }
            <main className="h-full overflow-y-auto relative bg-white">
              <div ref={appRef}>{props.children}</div>
            </main>
          </div>
        </div>
      </Suspense>
    </ScrollToTop.Provider>
  );
};

export default connect(null, auth.actions)(Layout);
