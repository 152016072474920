import axios from "axios";

export function refreshToken(refreshToken) {
  return axios.post("/auth/refresh-tokens", { refreshToken: refreshToken });
}

export function login(email, password) {
  return axios.post("/auth/login", { email, password });
}

export function verifyEmail(email) {
  return axios.post("/auth/forgot-password", { email });
}

export function changePassword(payload) {
  return axios.post("/auth/reset-password", payload);
}

export async function getUserByToken() {
  let { data } = await axios.get("/users/me");
  return data;
}
