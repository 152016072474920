import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import axios from "axios";
import * as _redux from "./redux";
import dotenv from "dotenv";
import store, { persistor } from "./redux/store";
import { SplashScreenProvider } from "./app/Context/LayoutContext";

import setupInterceptors from "./redux/setupInterceptors";

dotenv.config();

/**
 * Base URL of the website
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

_redux.setupAxios(axios, store);

ReactDOM.render(
  <SplashScreenProvider>
    <App store={store} persistor={persistor} basename={PUBLIC_URL} />
  </SplashScreenProvider>,

  document.getElementById("root")
);
setupInterceptors(store);
