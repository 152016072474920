import { createSlice } from "@reduxjs/toolkit";

const initialReportsState = {
  recapsReport: { retailer: {}, seller: {}, wholesaler: {} },
  invoicePurchaseOrderReport: {},
  manifestReport: {},
  manifestNumbers: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState: initialReportsState,
  reducers: {
    clearEntities: (state) => {
      state.recapsReport = { retailer: {}, seller: {}, wholesaler: {} };
    },
    setWholeSalerReport: (state, action) => {
      state.recapsReport = { ...state.recapsReport, wholesaler: action.payload };
    },
    setSellerReport: (state, action) => {
      state.recapsReport = { ...state.recapsReport, seller: action.payload };
    },
    setRetailerReport: (state, action) => {
      state.recapsReport = { ...state.recapsReport, retailer: action.payload };
    },
    setRecapsReport: (state, action) => {
      state.recapsReport = action.payload;
    },
    setInvoicePurchaseOrderReport: (state, action) => {
      state.invoicePurchaseOrderReport = action.payload;
    },
    setManifestReport: (state, action) => {
      state.manifestReport = action.payload;
    },
    setManifestNumbers: (state, action) => {
      state.manifestNumbers = action.payload;
    },
  },
});

// src/app/modules/Reports/_redux/reportsSlice.js
export const { actions: reportsActions, reducer } = reportsSlice;
