import { notificationSlice, callTypes } from "./notificationSlice";
import { showResponseMessage } from "../../ToastMessage/_redux/toastAction";
const { collection, query, orderBy, limit, onSnapshot, where, Timestamp, getDocs, writeBatch, doc, startAfter, updateDoc } = require("firebase/firestore");
import firebaseConnection from "../../../utils/firebaseConnection";
const { actions } = notificationSlice;

var allNotificationListner = () => {};
var countNotificationListner = () => {};
var latestNotifications = () => {};

function getFireStoreDateFormat(date, hour, min, sec, miliSec = 0) {
  date.setHours(hour, min, sec, miliSec); // +5 hours for Eastern Time
  const timestamp = Timestamp.fromDate(date);
  return timestamp; // ex. 1631246400
}

export const getNotifications = (data, startDate = new Date(), dataLimit = 10) => (dispatch) => {
  if (Object.values(data).length > 0 && typeof data !== "undefined" && typeof data !== "null") {
    dispatch(actions.startCall({ callType: callTypes.list }));
    dispatch(actions.clearNotifications());
    const collectionId = data.parentAccount + "-" + data.childAccount;
    const LatestNotiQuery = query(
      collection(firebaseConnection, collectionId),
      where("createdAt", "<=", getFireStoreDateFormat(startDate, 23, 59, 999)),
      orderBy("createdAt", "desc"),
      limit(dataLimit)
    );
    try {
      latestNotifications = onSnapshot(
        LatestNotiQuery,
        (snapshot) => {
          let newData = [];
          if (snapshot.size === 0) {
            dispatch(actions.clearNotifications());
            dispatch(actions.catchError({ callType: callTypes.list }));
          }
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              if (change.doc.id) {
                newData.push({ docId: change.doc.id, ...change.doc.data() });
              }
            }
            if (change.type === "modified") {
              dispatch(actions.updateNotification({ data: { docId: change.doc.id, notification: change.doc.data() } }));
            }
            if (change.type === "removed") {
              dispatch(actions.deleteNotification({ data: { docId: change.doc.id } }));
            }
          });
          dispatch(actions.setNotifications({ data: newData }));
        },
        (error) => {
          dispatch(actions.catchError({ callType: callTypes.list }));
          dispatch(
            showResponseMessage({
              message: "Notification get failed!",
              messageText: error?.response?.data?.message || error.message,
              messageType: "error",
              type: "Error",
            })
          );
        }
      );
    } catch (err) {
      dispatch(actions.catchError({ callType: callTypes.list }));
      dispatch(
        showResponseMessage({
          message: "Notification get failed!",
          messageText: err?.response?.data?.message || err.message,
          messageType: "error",
          type: "Error",
        })
      );
    }
  }
};

export const getAllNotification = (data, startDate = "", endDate = new Date(), dataLimit = 10) => (dispatch) => {
  if (Object.values(data).length > 0 && typeof data !== "undefined" && typeof data !== "null") {
    dispatch(actions.startCallAllNoti({ callType: callTypes.list }));
    dispatch(actions.clearAllNotifications());
    const collectionId = data.parentAccount + "-" + data.childAccount;
    let dataQuery = null;
    let totalCountQuery = null;
    if (endDate && startDate) {
      dataQuery = query(
        collection(firebaseConnection, collectionId),
        where("createdAt", ">=", getFireStoreDateFormat(startDate, 0, 0, 0)),
        where("createdAt", "<=", getFireStoreDateFormat(endDate, 23, 59, 59, 999)),
        orderBy("createdAt", "desc"),
        limit(dataLimit)
      );
      totalCountQuery = query(
        collection(firebaseConnection, collectionId),
        where("createdAt", ">=", getFireStoreDateFormat(startDate, 0, 0, 0)),
        where("createdAt", "<=", getFireStoreDateFormat(endDate, 23, 59, 59, 999)),
        orderBy("createdAt", "desc")
      );
    } else if (!endDate && startDate) {
      dataQuery = query(
        collection(firebaseConnection, collectionId),
        where("createdAt", ">=", getFireStoreDateFormat(startDate, 0, 0, 0)),
        orderBy("createdAt", "desc"),
        limit(dataLimit)
      );
      totalCountQuery = query(collection(firebaseConnection, collectionId), where("createdAt", ">=", getFireStoreDateFormat(startDate, 0, 0, 0)), orderBy("createdAt", "desc"));
    } else if (endDate && !startDate) {
      dataQuery = query(
        collection(firebaseConnection, collectionId),
        where("createdAt", "<=", getFireStoreDateFormat(endDate, 23, 59, 59, 999)),
        orderBy("createdAt", "desc"),
        limit(dataLimit)
      );
      totalCountQuery = query(
        collection(firebaseConnection, collectionId),
        where("createdAt", "<=", getFireStoreDateFormat(endDate, 23, 59, 59, 999)),
        orderBy("createdAt", "desc")
      );
    } else {
      dataQuery = query(
        collection(firebaseConnection, collectionId),
        where("createdAt", "<=", getFireStoreDateFormat(new Date(), 23, 59, 59, 999)),
        orderBy("createdAt", "desc"),
        limit(dataLimit)
      );
      totalCountQuery = query(
        collection(firebaseConnection, collectionId),
        where("createdAt", "<=", getFireStoreDateFormat(new Date(), 23, 59, 59, 999)),
        orderBy("createdAt", "desc")
      );
      // dispatch(actions.catchErrorAllNoti({ callType: callTypes.list }));
      // dispatch(
      //   showResponseMessage({
      //     message: "Date For Notifications",
      //     messageText: "Please Select Date For Notifications",
      //     messageType: "error",
      //     type: "Error",
      //   })
      // );
      // return false;
    }
    try {
      let count = 0;
      countNotificationListner = onSnapshot(
        totalCountQuery,
        (snapshot) => {
          count = snapshot.size;
          dispatch(actions.setTotalCount({ data: { totalCount: snapshot.size || 0 } }));
        },
        (error) => {
          dispatch(actions.catchErrorAllNoti({ callType: callTypes.list }));
          showResponseMessage({
            message: "Notification get failed!",
            messageText: error?.response?.data?.message || error.message,
            messageType: "error",
            type: "Error",
          });
        }
      );

      allNotificationListner = onSnapshot(
        dataQuery,
        (snapshot) => {
          let newData = [];
          if (snapshot.size === 0) {
            dispatch(actions.catchErrorAllNoti({ callType: callTypes.list }));
          }
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              if (change.doc.id) {
                newData.push({ docId: change.doc.id, ...change.doc.data() });
              }
            }
            if (change.type === "modified") {
              dispatch(actions.updateAllNotifications({ data: { docId: change.doc.id, notification: change.doc.data() } }));
            }
            if (change.type === "removed") {
              dispatch(actions.deleteAllNotification({ data: { docId: change.doc.id } }));
            }
          });
          if (count >= dataLimit && newData?.length === dataLimit) {
            dispatch(actions.setAllNotifications({ data: newData }));
          } else if (count < dataLimit) {
            dispatch(actions.setAllNotifications({ data: newData }));
          }
        },
        (error) => {
          dispatch(actions.catchErrorAllNoti({ callType: callTypes.list }));
          showResponseMessage({
            message: "Notification get failed!",
            messageText: error?.response?.data?.message || error.message,
            messageType: "error",
            type: "Error",
          });
        }
      );
    } catch (err) {
      dispatch(actions.catchErrorAllNoti({ callType: callTypes.list }));
      dispatch(
        showResponseMessage({
          message: "Notification get failed!",
          messageText: err?.response?.data?.message || err.message,
          messageType: "error",
          type: "Error",
        })
      );
    }
  }
};

export const update_all_notifications = (data) => async (dispatch) => {
  try {
    const batch = writeBatch(firebaseConnection);
    const collectionId = data.parentAccount + "-" + data.childAccount;
    const allDoc = query(collection(firebaseConnection, collectionId), where("message_read", "==", false), orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(allDoc);
    querySnapshot.forEach(async (item) => {
      // item.data() is never undefined for query doc snapshots
      const nycRef = doc(firebaseConnection, collectionId, item.id);
      batch.update(nycRef, { message_read: true, updatedAt: Timestamp.fromDate(new Date()) });
      // Commit the batch
    });
    await batch.commit();
  } catch (e) {
    dispatch(
      showResponseMessage({
        message: "Document update failed!",
        messageText: e?.response?.data?.message || e.message,
        messageType: "error",
        type: "Error",
      })
    );
  }
};

export const update_single_notifications = (data) => (dispatch) => {
  try {
    const collectionId = data.parentAccount + "-" + data.childAccount;
    const washingtonRef = doc(firebaseConnection, collectionId, data.docId);

    updateDoc(washingtonRef, {
      message_read: !data.message_read,
      updatedAt: Timestamp.fromDate(new Date()),
    })
      .then(() => {})
      .catch((e) => {
        dispatch(
          showResponseMessage({
            message: "Document update failed!",
            messageText: e?.response?.data?.message || e.message,
            messageType: "error",
            type: "Error",
          })
        );
      });
  } catch (e) {
    dispatch(
      showResponseMessage({
        message: "Document update failed!",
        messageText: e?.response?.data?.message || e.message,
        messageType: "error",
        type: "Error",
      })
    );
  }
};

export const getNextNotifications = (data, startDate = "", endDate = new Date(), dataLimit = 10, lastDocdate) => (dispatch) => {
  if (Object.values(data).length > 0 && typeof data !== "undefined" && typeof data !== "null") {
    dispatch(actions.startCallAllNoti({ callType: callTypes.list }));
    const collectionId = data.parentAccount + "-" + data.childAccount;
    let dataQuery = null;
    if (startDate && endDate) {
      dataQuery = query(
        collection(firebaseConnection, collectionId),
        where("createdAt", ">=", getFireStoreDateFormat(startDate, 0, 0, 0)),
        where("createdAt", "<=", getFireStoreDateFormat(endDate, 23, 59, 59, 999)),
        orderBy("createdAt", "desc"),
        startAfter(lastDocdate),
        limit(dataLimit)
      );
    } else if (!endDate && startDate) {
      dataQuery = query(
        collection(firebaseConnection, collectionId),
        where("createdAt", ">=", getFireStoreDateFormat(startDate, 0, 0, 0)),
        orderBy("createdAt", "desc"),
        startAfter(lastDocdate),
        limit(dataLimit)
      );
    } else if (endDate && !startDate) {
      dataQuery = query(
        collection(firebaseConnection, collectionId),
        where("createdAt", "<=", getFireStoreDateFormat(endDate, 23, 59, 59, 999)),
        orderBy("createdAt", "desc"),
        startAfter(lastDocdate),
        limit(dataLimit)
      );
    } else {
      dataQuery = query(
        collection(firebaseConnection, collectionId),
        where("createdAt", "<=", getFireStoreDateFormat(new Date(), 23, 59, 59, 999)),
        orderBy("createdAt", "desc"),
        startAfter(lastDocdate),
        limit(dataLimit)
      );
      // dispatch(actions.catchErrorAllNoti({ callType: callTypes.list }));
      // dispatch(
      //   showResponseMessage({
      //     message: "Date For Notifications",
      //     messageText: "Please Select Date For Notifications",
      //     messageType: "error",
      //     type: "Error",
      //   })
      // );
      // return false;
    }
    try {
      allNotificationListner = onSnapshot(
        dataQuery,
        (snapshot) => {
          let newData = [];
          if (snapshot.size === 0) {
            dispatch(actions.catchErrorAllNoti({ callType: callTypes.list }));
          }
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              newData.push({ docId: change.doc.id, ...change.doc.data() });
            }
            if (change.type === "modified") {
              dispatch(actions.updateAllNotifications({ data: { docId: change.doc.id, notification: change.doc.data() } }));
            }
            if (change.type === "removed") {
              dispatch(actions.deleteAllNotification({ data: { docId: change.doc.id } }));
            }
          });
          dispatch(actions.setNextNotifications({ data: newData }));
        },
        (error) => {
          dispatch(actions.catchErrorAllNoti({ callType: callTypes.list }));
          showResponseMessage({
            message: "Notification get failed!",
            messageText: error?.response?.data?.message || error.message,
            messageType: "error",
            type: "Error",
          });
        }
      );
    } catch (err) {
      dispatch(actions.catchErrorAllNoti({ callType: callTypes.list }));
      dispatch(
        showResponseMessage({
          message: "Notification get failed!",
          messageText: err?.response?.data?.message || err.message,
          messageType: "error",
          type: "Error",
        })
      );
    }
  }
};

export const destoryFirestoreListiner = () => async () => {
  allNotificationListner();
  countNotificationListner();
};

export const removeMessageListner = () => async () => {
  latestNotifications();
  allNotificationListner();
  countNotificationListner();
};
