import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  kitsList: [],
  totalKits: 0,
  isLoading: false,
};

export const KitsSlice = createSlice({
  name: "kits",
  initialState: initialState,
  reducers: {
    restListCount: (state, action) => {
      state.totalKits = action.payload;
    },
    setKitsList: (state, action) => {
      const { items, totalItems } = action.payload.data;
      state.kitsList = items;
      state.totalKits = totalItems;
    },
    setLoading: (state, action) => {
      const value = action.payload;
      state.isLoading = !!value ? value : !state.isLoading;
    },
  },
});
